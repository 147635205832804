


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconAndroid: () => import('@/components/UI/icons/IconAndroid.vue'),
    IconApple: () => import('@/components/UI/icons/IconApple.vue'),
    IconGooglePlay: () => import('@/components/UI/icons/IconGooglePlay.vue'),
    IconLinux: () => import('@/components/UI/icons/IconLinux.vue'),
    IconRuMarket: () => import('@/components/UI/icons/IconRuMarket.vue'),
    IconWindows: () => import('@/components/UI/icons/IconWindows.vue'),
  },
})
export default class MobileLoginAppBtn extends Vue {
  @Prop({ type: String, required: true }) private readonly href!: string
  @Prop({ type: String, required: true }) private readonly title!: string
  @Prop({ type: String }) private readonly type?:
    | 'android'
    | 'appstore'
    | 'googleplay'
    | 'linux'
    | 'rumarket'
    | 'windows'

  get iconComponentName () {
    switch (this.type) {
      case 'android': return 'IconAndroid'
      case 'appstore': return 'IconApple'
      case 'googleplay': return 'IconGooglePlay'
      case 'linux': return 'IconLinux'
      case 'rumarket': return 'IconRuMarket'
      case 'windows': return 'IconWindows'
      default: throw new Error('Unexpected btn type')
    }
  }
}
